<template>
  <va-show :item="item">
    <va-field source="sender_name"></va-field>
    <va-field source="sender_email" type='email'></va-field>
    <va-field source="status"></va-field>
    <va-field source="created_at" type="date"></va-field>

    <va-field source="book.title"></va-field>
    <va-field source="book.volume"></va-field>
    <va-field source="book.author"></va-field>
    <va-field source="book.publisher"></va-field>
    <va-field source="book.author_death_date"></va-field>
    <va-field source="book.language"></va-field>
    <va-field source="book.research"></va-field>
    <va-field source="book.pdf_file"></va-field>

    <va-field source="book.tags" v-slot="{ value }">
      <v-chip-group>
        <v-chip v-for="(item, i) in value" :key="i">
          <va-select-field source="tags" :item="item"></va-select-field>
        </v-chip>
      </v-chip-group>
    </va-field>

  </va-show>
</template>

<script>
export default {
  props: ["item"],
};
</script>
