import Vue from "vue";
import VuetifyAdmin from "vuetify-admin";

import "vuetify-admin/src/loader";

import {
  jsonServerDataProvider,
  jwtAuthProvider,
} from "../providers";
import { en, fr } from "vuetify-admin/src/locales";

import router from "@/router";
import routes from "@/router/admin";
import store from "@/store";
import i18n from "@/i18n";
import resources from "@/resources";
import axios from "axios";
import trimEnd from "lodash/trimEnd";

/**
 * Load Admin UI components
 */
Vue.use(VuetifyAdmin);

/**
 * Axios instance
 */
const baseURL = process.env.VUE_APP_ROOT_URL || "https:/admin.mtaleb.net";

const http = axios.create({
  baseURL,
  headers: { "X-Requested-With": "XMLHttpRequest", "Content-Type": "application/json" },
});

http.interceptors.request.use(function (config) {
  let jwt = localStorage.getItem('jwt_token');
  config.headers.Authorization =  'Bearer ' + jwt;
return config;
});

http.interceptors.response.use(
  (response) => {
    return response;
  },
  ({ message, response }) => {
    if (response) {
      let { status, statusText, data } = response;

      return Promise.reject({
        status,
        message: statusText,
        ...data,
      });
    }

    return Promise.reject({
      message,
    });
  }
);

en['confirm']['delete_message']= '';
en['confirm']['delete_many_message'] = '';

export default new VuetifyAdmin({
  router,
  store,
  i18n,
  title: "Admin Dashboard",
  routes,
  locales: { en, fr },
  translations: ["en", "fr"],
  authProvider: jwtAuthProvider(http),
  dataProvider: jsonServerDataProvider(http),
  resources,
  http,
  options: {
    dateFormat: "long",
    tinyMCE: {
      language: navigator.language.replace("-", "_"),
      imageUploadUrl: "/api/upload",
      fileBrowserUrl: `${trimEnd(baseURL, "/")}/elfinder/tinymce5`,
    },
  },
});
