<template>
  <div>
    <v-row>

      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="success"
          icon="mdi-book"
          title="Published Books"
          :value="published_books"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="info"
          icon="mdi-book-outline"
          title="Received Books"
          :value="received_books"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="orange"
          icon="mdi-ocr"
          title="Queued OCR Tasks"
          :value="queued_ocr_tasks"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="success"
          icon="mdi-ocr"
          title="Completed OCR Tasks"
          :value="completed_ocr_tasks"
        />
      </v-col>

    </v-row>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      published_books: 0,
      received_books: 0,
      queued_ocr_tasks: 0,
      completed_ocr_tasks: 0,
    };
  },
  methods:{
      getUnits(){
        let jwt = localStorage.getItem("jwt_token");
        let auth_header =  "Bearer " + jwt;
        let stats_url = process.env.VUE_APP_API_URL + '/stats';
        axios.get(stats_url, { headers: {"Authorization": auth_header}})
          .then(response => {
            this.published_books = response.data['published_books'];
            this.received_books = response.data['received_books'];
            this.queued_ocr_tasks = response.data['queued_ocr_tasks'];
            this.completed_ocr_tasks = response.data['completed_ocr_tasks'];
          }).catch(function (error) {
            // console.log(error)
          })
      },
  },

  beforeMount(){
      this.getUnits()
  },
};
</script>
