export default (i18n, admin) => [
  {
    icon: "mdi-view-dashboard",
    text: i18n.t("menu.dashboard"),
    link: "/",
  },
  { divider: true },
  { heading: i18n.t("menu.books") },
  ...admin.getResourceLinks([
    "offline_books",
    "online_books",
    "trash",
  ]),
  { divider: true },
  { heading: i18n.t("menu.management") },
  admin.getResourceLink("users"),
  admin.getResourceLink("tops_tasks"),
];
