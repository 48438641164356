<template>
  <v-layout row justify-center>
    <v-dialog v-model="loading" persistent content content-class="centered-dialog">
      <v-container fill-height>
        <v-layout column justify-center align-center>
          <v-progress-circular :rotate="360" :size="100" :width="15" :value="percent" :color="progressColor">  {{ percent }}</v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>
  </v-layout>
</template>

<script>
    export default {
        name: "Loading",
        data: function () {
            return {
              loading: this.isLoading,
              loadingMessage: this.message,
              percent: this.value,
            }
        },
        props: {
            isLoading: {type: Boolean, default: false},
            value: {type: Number, default: 0},
            message: {type: String, default: ''},
            progressColor: {type: String, default: '#20302e'},
        },
        watch: {
          isLoading (val) {
            setTimeout(() => (this.loading = val), 1000)
            if (!val){
              this.percent = 0;
            }
          },
          value (val) {
            setTimeout(() => (this.percent = val), 1000)
          },

        },
    }
</script>

<style>
  .dialog.centered-dialog,
  .v-dialog.centered-dialog
 {
    /* background: rgb(255, 255, 255, 0.2); */
    box-shadow: none;
    border-radius: 6px;
    width: auto;
    color: #20302e;
  }
</style>
