<template>
  <va-show :item="item">
    <va-field source="title"></va-field>
    <va-field source="volume"></va-field>
    <va-field source="author"></va-field>
    <va-field source="publisher"></va-field>
    <va-field source="isbn"></va-field>
    <va-field source="author_death_date"></va-field>
    <va-field source="print"></va-field>
    <va-field source="press"></va-field>
    <va-field source="publication_date"></va-field>
    <va-field source="language"></va-field>
    <va-field source="sender_name"></va-field>
    <va-field source="sender_phone"></va-field>
    <va-field source="sender_email" type="email"></va-field>
    <va-field source="ocr" type="boolean"></va-field>
    <va-field source="notes"></va-field>
    <va-field source="research"></va-field>
    <va-field source="date"></va-field>
    <va-field source="abx_file_name"></va-field>
    <va-field source="pdf_file_name"></va-field>
    <va-field source="created_at" type="date"></va-field>
    <va-field source="pages_match" type="boolean"></va-field>
    <va-field source="has_text" type="boolean"></va-field>
    <va-field source="manuscript" type="boolean"></va-field>

    <va-field source="verified" type="boolean"></va-field>
    <va-field source="tags" v-slot="{ value }">
      <v-chip-group>
        <v-chip v-for="(item, i) in value" :key="i">
          <va-select-field source="tags" :item="item"></va-select-field>
        </v-chip>
      </v-chip-group>
    </va-field>
  </va-show>
</template>

<script>
export default {
  props: ["item"],
};
</script>
