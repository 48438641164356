<template>
<div>
  <loading
    :isLoading="isLoading"
    :value="loadingValue"
  ></loading>
  <va-aside-layout :title="asideTitle">
    <offline-books-show v-if="show" :item="item"></offline-books-show>
    <online-books-form v-else :id="id" :item="item" @saved="onSaved"></online-books-form>
  </va-aside-layout>
  <base-material-card :icon="resource.icon" :title="title">
    <va-list
      ref="list"
      :filters="filters"
      disable-create
      dense
      @action="onAction"
    >
        <template v-slot:[`bulk.actions`]>
          <va-bulk-action-button
            :label="$t('unpublish')"
            icon="mdi-book-outline"
            color="success"
            :action="{ published: false }"
            text
          ></va-bulk-action-button>
          <va-action-button
            :label="$t('export csv')"
            icon="mdi-download"
            color="blue"
            @click="onExportSelected"
            text
          ></va-action-button>
          <va-action-button
            :label="$t('download')"
            icon="mdi-cloud-download"
            color="#00008B"
            @click="onDownloadSelected"
            text
          ></va-action-button>
        </template>

      <va-data-table :fields="fields"
        dense
        disable-show-redirect
        disable-edit-redirect
        disable-clone
        :multiSort=false
        @item-action="onAction"
      >
        <template v-slot:[`item.actions`]="row">
          <va-action-button
            :label="$t('abx')"
            icon="mdi-text"
            color="black"
            @click="downloadAbxFile(row.item)"
            text
            hideLabel
          ></va-action-button>
          <va-action-button
            :label="$t('pdf')"
            icon="mdi-file-pdf-outline"
            color="red"
            @click="downloadPdfFile(row.item)"
            hideLabel
            text
          ></va-action-button>
        </template>

        <template v-slot:[`field.tags`]="{ value }">
          <v-chip-group column>
            <v-chip color="yellow" small v-for="(item, i) in value" :key="i">
              <va-select-field source="tags" :item="item"></va-select-field>
            </v-chip>
          </v-chip-group>
        </template>
      </va-data-table>
    </va-list>
  </base-material-card>
</div>
</template>

<script>
import Papa from "papaparse";
import axios from "axios";
import Loading from "../../components/Loading"

export default {
  components: { Loading },
  props: ["resource", "title"],
  data() {
    return {
      filters: [
        { source: "verified", type: "boolean" },
      ],
      fields: [
        { source: "title", sortable: true },
        "volume",
        { source: "author", sortable: true },
        { source: "publisher", sortable: true },
        "tags",
        { source: "verified", type: "boolean", editable: false },
        {
          source: "created_at",
          type: "date",
          sortable: true,
        },
      ],
      asideTitle: null,
      id: null,
      item: null,
      show: false,
      isLoading: false,
      loadingValue: 0,
    };
  },
  methods:{
    async onAction({ action, title, id, item }) {
      console.debug(action)
      this.asideTitle = 'Book Info';
      this.id = id;
      this.show = action === "show";
      this.item = item;
      // this.item.name = item.title
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
    updateProgressBarValue(progressValue){
      this.loadingValue = progressValue;
    },
    onExportSelected() {
      let data = JSON.parse(JSON.stringify(this.$refs.list.listState.selected));

      const csv = Papa.unparse(
      data.map((item) => {
        for (let prop in item) {
          if (item[prop] instanceof Array){
            item[prop] = item[prop].join();
          }
          else if (typeof item[prop] === "object") {
            delete item[prop];
          }
        }
        return item;
      }),
      {
        quotes: false, //or array of booleans
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ",",
        header: true,
        newline: "\r\n",
        skipEmptyLines: false, //or 'greedy',
        columns: null, //or array of strings
      }
    );

    const fileName = 'books_list';
    const blob = new Blob([csv], { type: "text/csv" });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, `${fileName}.csv`);
      return;
    }
    const fakeLink = document.createElement("a");
    fakeLink.style.display = "none";
    document.body.appendChild(fakeLink);
    fakeLink.setAttribute("href", URL.createObjectURL(blob));
    fakeLink.setAttribute("download", `${fileName}.csv`);
    fakeLink.click();
    },
    async downloadFile(url, type, label){
      let jwt = localStorage.getItem("jwt_token");
      let auth_header =  "Bearer " + jwt;
      await axios.get(url, { responseType: "blob", headers: {"Authorization": auth_header},
                            onDownloadProgress: progressEvent => {
                              const total = parseFloat(progressEvent.total);
                              const current = parseFloat(progressEvent.loaded);

                              let percentCompleted = Math.floor(current / total * 100)
                              this.updateProgressBarValue(percentCompleted);
                            }
      })
        .then(response => {
          const blob = new Blob([response.data], { type: type })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = label
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(console.error)
    },
    async downloadAbxFile(item){
      this.isLoading = true;
      this.loadingValue = 0;
      try {
        await this.downloadFile(item.abx_url, "text/plain", item.abx_file_name);
      }
      finally {
        this.isLoading = false;
      }

    },
    async downloadPdfFile(item){
      this.isLoading = true;
      this.loadingValue = 0;
      try {
        await this.downloadFile(item.pdf_url, "application/pdf", item.pdf_file_name);
      }
      finally {
        this.isLoading = false;
      }
    },
    async onDownloadSelected(){
      // this.$refs.list.listState.loading = true;
      this.isLoading = true;
      this.loadingValue = 0;
      try {
        let items = JSON.parse(JSON.stringify(this.$refs.list.listState.selected));
        let ids = items.map(item => item.id);
        let url = process.env.VUE_APP_API_URL + "/download_zip?ids=" + ids.join(",");
        await this.downloadFile(url, 'application/zip', 'mtaleb_books');
      }
      finally {
        // this.$refs.list.listState.loading = false;
        this.isLoading = false;
      }
    },
  },
};
</script>
