export default [
  {
    name: "offline_books",
    icon: "mdi-book-outline",
    label: (r) => `${r.title} (${r.isbn})`,
    translatable: true,
    routes: ["list"],
    permissions: ["*", "offline_books"],
    // autocompleteFields: ["id", "title", "isbn"],
  },
  {
    name: "online_books",
    icon: "mdi-book",
    label: (r) => `${r.title} (${r.isbn})`,
    translatable: true,
    permissions: ["*", "online_books"],
  },
  {
    name: "trash",
    icon: "mdi-trash-can",
    label: (r) => `${r.title} (${r.isbn})`,
    translatable: true,
    permissions: ["*", "trash"],
  },
  {
    name: "users",
    icon: "mdi-account",
    label: "name",
    routes: ["list"],
    permissions: ["*", "users"],
  },
  {
    name: "tops_tasks",
    icon: "mdi-ocr",
    label: "name",
    routes: ["list"],
    permissions: ["*", "tops"],
  },  
];
