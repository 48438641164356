<template>
  <va-form
    :id="id"
    :item="item"
    disable-redirect
    @saved="$emit('saved')"
    v-model="model"
  >
    <va-text-input source="title"></va-text-input>
    <va-text-input source="volume"></va-text-input>
    <va-text-input source="author"></va-text-input>
    <va-text-input source="publisher"></va-text-input>
    <va-text-input source="isbn"></va-text-input>
    <va-text-input source="author_death_date"></va-text-input>
    <va-text-input source="print"></va-text-input>
    <va-text-input source="press"></va-text-input>
    <va-text-input source="publication_date"></va-text-input>
    <va-text-input source="notes"></va-text-input>
    <va-text-input source="research"></va-text-input>
    <va-text-input source="date"></va-text-input>
    <va-text-input source="tags"></va-text-input>
    <va-radio-group-input source="language" row></va-radio-group-input>
    <va-boolean-input source="ocr"></va-boolean-input>
    <va-boolean-input source="pages_match"></va-boolean-input>
    <va-boolean-input source="has_text"></va-boolean-input>
    <va-boolean-input source="manuscript"></va-boolean-input>
    <va-boolean-input source="verified"></va-boolean-input>

    <va-save-button></va-save-button>
  </va-form>
</template>

<script>
export default {
  props: ["id", "item"],
};
</script>
