<template>
  <div>
    <loading
    :isLoading="isLoading"
    :value="loadingValue"
    ></loading>
    <va-aside-layout :title="asideTitle">
      <tops-tasks-show v-if="show" :item="item"></tops-tasks-show>
    </va-aside-layout>
    <base-material-card :icon="resource.icon" :title="title">
      <va-list
        ref="list"
        :filters="filters"
        disable-create
        @action="onAction"
      >
        <template v-slot:[`bulk.actions`]>
          <va-action-button
            :label="$t('export')"
            icon="mdi-download"
            color="blue"
            @click="onExportSelected"
            text
          ></va-action-button>
        </template>

        <va-data-table
          :fields="fields"
          dense
          disable-create-redirect
          disable-show-redirect
          disable-edit
          disable-clone
          :multiSort=false
          @item-action="onAction"
        >
        <template v-slot:[`item.actions`]="row">
          <va-action-button
            :label="$t('text')"
            icon="mdi-text"
            color="black"
            @click="downloadTextFile(row.item)"
            text
            hideLabel
          ></va-action-button>
          <va-action-button
            :label="$t('pdf')"
            icon="mdi-file-pdf-outline"
            color="red"
            @click="downloadPdfFile(row.item)"
            hideLabel
            text
          ></va-action-button>
        </template>
        </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
import Papa from "papaparse";
import axios from "axios";
import Loading from "../../components/Loading"

export default {
  components: { Loading },
  components: {
  },
  props: ["resource", "title"],
  data() {
    return {
      filters: [
        { source: "sender_email"},
        {
          source: "status",
          type: "select",
          attributes: {
            multiple: true,
          },
        },
      ],
      fields: [
        { source: "sender_name"},
        { source: "sender_email", type: "email" },
        { source: "book.title" },
        { source: "book.author" },
        "status",
        {
          source: "created_at",
          type: "date",
          sortable: true,
        },
      ],
      asideTitle: null,
      id: null,
      item: null,
      show: false,
      isLoading: false,
      loadingValue: 0,
    };
  },
  methods: {
    async onAction({ action, title, id, item }) {
      this.asideTitle = title;
      this.id = id;
      this.show = action === "show";
      this.item = item;
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
    updateProgressBarValue(progressValue){
      this.loadingValue = progressValue;
    },
    onExportSelected() {
      let data = JSON.parse(JSON.stringify(this.$refs.list.listState.selected));

      const csv = Papa.unparse(
      data.map((item) => {
        for (let prop in item) {
          if (item[prop] instanceof Array){
            item[prop] = item[prop].join();
          }
          else if (typeof item[prop] === "object") {
            delete item[prop];
          }
        }
        return item;
      }),
      {
        quotes: false, //or array of booleans
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ",",
        header: true,
        newline: "\r\n",
        skipEmptyLines: false, //or 'greedy',
        columns: null, //or array of strings
      }
    );

    const fileName = 'tops_report';
    const blob = new Blob([csv], { type: "text/csv" });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, `${fileName}.csv`);
      return;
    }
    const fakeLink = document.createElement("a");
    fakeLink.style.display = "none";
    document.body.appendChild(fakeLink);
    fakeLink.setAttribute("href", URL.createObjectURL(blob));
    fakeLink.setAttribute("download", `${fileName}.csv`);
    fakeLink.click();
    },
    async downloadFile(url, type, label){
      let jwt = localStorage.getItem("jwt_token");
      let auth_header =  "Bearer " + jwt;
      await axios.get(url, { responseType: "blob", headers: {"Authorization": auth_header},
                            onDownloadProgress: progressEvent => {
                              const total = parseFloat(progressEvent.total);
                              const current = parseFloat(progressEvent.loaded);

                              let percentCompleted = Math.floor(current / total * 100)
                              this.updateProgressBarValue(percentCompleted);
                            }
      })
        .then(response => {
          const blob = new Blob([response.data], { type: type })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          if(label != null) {
            link.download = label
          }
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(console.error)
    },
    async downloadTextFile(item){
      this.isLoading = true;
      this.loadingValue = 0;
      try {
        await this.downloadFile(item.text_url, "text/plain", item.text_url.substring(item.text_url.lastIndexOf('/')+1));
      }
      finally {
        this.isLoading = false;
      }
    },
    async downloadPdfFile(item){
      this.isLoading = true;
      this.loadingValue = 0;
      try {
        await this.downloadFile(item.pdf_url, "application/pdf", item.pdf_url.substring(item.pdf_url.lastIndexOf('/')+1));
      }
      finally {
        this.isLoading = false
      }
    },
  },
};
</script>
